import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Button,
  CardActions,
  Card,
  CardMedia,
  createStyles,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {useIntl} from "react-intl";
import {formatAsThousands, thousands} from '../../../../../utils/utils';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import { IProduct } from '../../../../../interfaces/product';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { IUser, UserRoles } from '../../../../../interfaces/user';
import { getProductImage } from '../../utils/getProductImage';
import { useCreateChat } from '../../../chats/chatsPage/hooks/useCreateChat';
import { IAppState } from '../../../../../store/rootDuck';
import ProductIcons from '../../../../../components/ProductIcons';
import { ICart, IGuestCart } from '../../../cart/interfaces';

const useStyles = makeStyles(theme =>
  createStyles({
    productCard: {
      minWidth: '100%',
      // maxWidth
      height: '450px',
      padding: 15,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',
      display: 'grid',
      gridAutoFlow: 'row',
      gridTemplate: 'auto 1fr minmax(40px, auto) / 1fr',
      // borderRadius: '0 0 20px 0',
      '&:hover': {
        transform: 'scale(1.03)',
      },
        [theme.breakpoints.up(425)]: {
            width: 200,
        },
      // [theme.breakpoints.up('md')]: {
      //   width: 176,
      // },
        [theme.breakpoints.down(425)]: {
            padding: '5px 5px',
            height: '430px',
            width: 160,
        },
    },
    edit: {
      backgroundColor: '#216214',
      // borderRadius: '20px 0',
      padding: '5px 30px',
      color: '#fff',
      fontSize: 14,
      fontWeight: 900,
      border: 'none',
        [theme.breakpoints.down(380)]: {
            padding: '5px 15px',
        },
    },
      editDe: {
          backgroundColor: '#216214',
          // borderRadius: '20px 0',
          padding: '5px 30px',
          color: '#fff',
          fontSize: 14,
          fontWeight: 900,
          border: 'none',
          [theme.breakpoints.down(545)]: {
              padding: '5px 10px',
              fontSize: 13,
          },
          [theme.breakpoints.down(395)]: {
              padding: '5px 5px',
              fontSize: 12,
          },
      },
    delete: {
      color: '#441861',
      border: 'none',
      backgroundColor: 'transparent',
      fontSize: 14,
      fontWeight: 900,
        [theme.breakpoints.down(380)]: {
            padding: '5px 15px',
        },
    },
      deleteDe: {
          color: '#441861',
          border: 'none',
          backgroundColor: 'transparent',
          fontSize: 14,
          fontWeight: 900,
          [theme.breakpoints.down(545)]: {
              padding: '5px 10px',
              fontSize: 13,
          },
          [theme.breakpoints.down(395)]: {
              padding: '5px 5px',
              fontSize: 12,
          },
      },
    absoluteImg: {
      position: 'absolute',
      zIndex: 0,
      bottom: 20,
      left: 30,
    },
    butCartText: {
      fontSize: 16,
      color: '#fff',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    addFav: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
      iconStyle: {
          color: '#fff',
          height:25,
          width:25,
      },
    favBtn: {
      backgroundColor: 'rgba(68, 24, 97, 0.65)',
      border: 'none',
      height: 50,
     // fontSize: 30,
      color: '#fff',
      width: 50,
      transition: 'all .3s ease-out',
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
    favText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#441861',
      margin: 0,
      width: 50,
      textAlign: 'center',
    },
  })
);

interface IProductsProps {
  item: IProduct;

  handleSetCountCart?: (type: 'dec' | 'inc', count: number, product: IProduct) => void;
  fetch?: (data: { page: number; perPage: number }) => void;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  setProductCountLoading?: boolean;
  handleCartDialog: any;
  guestCart?: IGuestCart | null;
  cart?: ICart | null;
}

const ProductsGridItem: FC<IProductsProps> = ({
  item,
  fetch,
  viewAction,
  editAction,
  handleDeleteDialog,
  isAdmin,
  me,
  addProductLoading,
  handleCartDialog,
  guestCart,
  cart,
  handleSetCountCart = () => {},
  setProductCountLoading,
}) => {
  const fm = useFormatMessage();
  const intl = useIntl();
  const isEnglish = intl.locale === "en"
  const classes = useStyles();
  const [heightImg, setHeightImg] = useState(268);
  const history = useHistory();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const [createChatFetch, loadingCreated, createChat] = useCreateChat();
  const isAuthorized = useSelector((state: IAppState) => state.auth.user != null);
  // eslint-disable-next-line prefer-const
  let productCount = useMemo(() => {
    // if (!isAuthorized && item && guestCart) {
    //   const product = guestCart.items.find(elem => elem.product.id === item?.id);
    //   if (product) {
    //     return product.count;
    //   }
    if (true && item && cart) {
      const product = cart.items.find(elem => elem.product.id === item.id);
      if (product) {
        return product.count;
      }
    }
    return 0;
  }, [isAuthorized, item, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  // const newData =
  const getStock = useCallback(
    (product: IProduct) => {
      return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
    },
    [isBuyer, me]
  );

  const newChat = useCallback((userId?: string | number) => {
    createChatFetch({ userId });
  }, []);

  const photoProduct = getProductImage(item);
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey || event.button === 1) {
            return;
        }
        event.preventDefault();
        viewAction(item);
    };

  return (
    <Card key={item.id} className={classes.productCard}>
        <Link
            to={`/viewproduct/${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick}
        >
      <Box
       // onClick={() => viewAction(item)}
        style={{
          display: 'flex',
          // gap: 8,
          justifyContent: 'center',
          zIndex: 1,
          position: 'relative'
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
            position: 'absolute',
            left: 0
            // width: '16%',
          }}
        >
          {item.parameter_values?.map(el => {
            if (el.parameter_name !== 'Marks') return;
            return Array.isArray(el.value) && el.value.map(key => <ProductIcons type={key} />);
          })}
        </Box>
        {photoProduct ? (
          <CardMedia
            style={{
              height: heightImg,
              minHeight: 268,
              objectFit: 'contain',
              width: '50%',
              // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
            }}
            title={item.name}
            src={photoProduct}
            component='img'
            // onError={(e: any) => {
            //   e.target.src = toAbsoluteUrl('/images/placeholder.png');
            // }}
          />
        ) : (
          <div
            style={{
              height: heightImg,
              minHeight: 268,
              width: '50%',
              // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
            }}
          />
        )}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
            position: 'absolute',
            right: 0
            // width: '16%',
          }}
        >
          {item.rating && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 45,
                height: 45,
              }}>
              <img
                style={{position: 'absolute'}}
                src={toAbsoluteUrl('/media/icons/rate-icons.svg')} alt="" width={45} height={45}/>
              <Typography
                style={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: '#000000',
                  zIndex: 100,
                }}
              >
                {item.rating}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography
              style={{
                fontSize: 10,
                fontWeight: 400,
                textAlign: 'center',
              }}
            >
              {item.parameter_values?.map(
                el => el.parameter_name === 'Alkohol %Vol.' && el.value + ' %'
              )}
            </Typography>
            <Typography
              style={{
                fontSize: 10,
                fontWeight: 400,
                textAlign: 'center',
              }}
            >
              {item.parameter_values?.map(
                el => el.parameter_name === 'Volume, l' && el.value + ' l'
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <img */}
      {/*   className={classes.absoluteImg} */}
      {/*   src={toAbsoluteUrl('/images/bg-name.svg')} */}
      {/*   alt='' */}
      {/* /> */}
      <Typography
        style={{
          fontSize: 16,
          fontWeight: 300,
          margin: '8px 0 4px 0',
          padding: '0 16%',
          textAlign: 'center',
          display: '-webkit-box',
          // @ts-ignore
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': '3',
          overflow: 'hidden',
        }}
      >
        {item.name}
      </Typography>
      {!!item.old_price && !!item.discount_price && (
        <Typography
          color='inherit'
          component='p'
          style={{
            lineHeight: 1.3,
            color: '#000',
            fontSize: 10,
            fontWeight: 400,
            textAlign: 'center',
            textDecoration: 'line-through'
          }}
        >
          CZK {formatAsThousands(item.old_price)}
        </Typography>
      )}
      <Typography
        color='inherit'
        component='p'
        style={{
          marginBottom: 4,
          lineHeight: 1.3,
          color: '#ad1d11',
          fontSize: 13,
          fontWeight: 400,
          textAlign: 'center',
  
        }}
      >
        CZK {formatAsThousands(item.price)}
      </Typography>
    </Link>
      {(isBuyer || !isAuthorized) &&
        (productCount > 0 ? (
          <div className={classes.addFav}>
            <button
              disabled={setProductCountLoading}
              onClick={event => {
                event.stopPropagation();
                // eslint-disable-next-line no-plusplus
                handleSetCountCart('dec', --productCount!, item);
              }}
              className={classes.favBtn}
              type='button'
            >
                <RemoveIcon className={classes.iconStyle} />
            </button>
            <p className={classes.favText}>{productCount}</p>
            <button
              disabled={setProductCountLoading}
              onClick={event => {
                event.stopPropagation();
                // eslint-disable-next-line no-plusplus
                handleSetCountCart('inc', ++productCount!, item);
              }}
              className={classes.favBtn}
              type='button'
            >
                <AddIcon className={classes.iconStyle} />
            </button>
          </div>
        ) : (
          <Button
            disabled={addProductLoading}
            onClick={e => {
              e.stopPropagation();
              handleCartDialog(item);
            }}
            color='primary'
            style={{
              fontSize: 14,
              // borderRadius: '20px 0 25px 0',
              backgroundColor: '#4e246a',
              color: '#fff',
              padding: '6px 20px 6px 16px',
              display: 'flex',
              maxWidth: 150,
              height: 50,
              alignItems: 'center',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              margin: '0 auto',
            }}
          >
            {/* <img */}
            {/*   src={toAbsoluteUrl('/images/cart-plus.svg')} */}
            {/*   alt='' */}
            {/* /> */}
            <div
              style={{
                display: 'flex',
                gap: 10,
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: 20,
                padding: '0 22px'
              }}
            >
              <img src={toAbsoluteUrl('/images/basket.svg')} alt='' />
              {fm('PRODUCT.BUTTON.ADD_CART')}
            </div>
          </Button>
        ))}
      <CardActions style={{ justifyContent: 'center', padding: 0 }}>
        {(isAdmin || isManager) && (
          <>
            {handleDeleteDialog && (
              <button
                className={!isEnglish ? classes.deleteDe : classes.delete}
                type='button'
                onClick={() => handleDeleteDialog(item)}
                color='secondary'
                style={{ marginLeft: 6 }}
              >
                {fm('COMMON.BUTTON.DELETE')}
              </button>
            )}

            {editAction && (
              <button
                className={!isEnglish ? classes.editDe : classes.edit}
                type='button'
                onClick={() => editAction(item)}
                color='primary'
              >
                {fm('edit')}
              </button>
            )}
          </>
        )}
        {/* {(me?.roles.includes(UserRoles.ROLE_VENDOR) ||
                  me?.roles.includes(UserRoles.ROLE_BUYER)) && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      height: 45,
                    }}
                  >
                    <Button
                      style={{ width: '90%' }}
                      color='primary'
                      variant='outlined'
                      onClick={() => {
                        // newChat(me?.id);
                        history.push({
                          pathname: `/chats`,
                          state: {
                            createUserChat: me?.id,
                          },
                        });
                      }}
                    >
                      {fm('Поддержка')}
                    </Button>
                  </div>
                )} */}
      </CardActions>
    </Card>
  );
};
export default ProductsGridItem;
