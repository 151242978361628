import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import {Box, CircularProgress, createStyles, Input, InputAdornment, makeStyles, Typography} from '@material-ui/core';
import Preloader from "../../../app/components/ui/Preloader/Preloader";
import {IAppState} from "../../../app/store/rootDuck";
import {actions as productActions} from "../../../app/store/ducks/product.duck";
import {API_DOMAIN} from "../../../app/constants";
import AddIcon from "@material-ui/icons/Add";
import {formatAsThousands} from "../../../app/utils/utils";
import {actions as cartTypesActions} from "../../../app/store/ducks/cart.duck";
import {IProduct} from "../../../app/interfaces/product";
import {toAbsoluteUrl} from "../../utils/utils";
import {debounce} from "lodash";
import {useIntl} from "react-intl";

const useStyles = makeStyles(theme => (
    createStyles({
      emptyPaper: {
        minHeight: 2,
        display: 'flex',
        alignItems: 'center',
      },
      img: {
        height: 75,
        width: 75,
        objectFit: 'contain',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          height: 150,
        },
      },
      input: {
        fontSize: "20px !important",
        padding: 0,
        [theme.breakpoints.down('md')]: {
     
        }
      },
      favBtn: {
        backgroundColor: 'rgba(68, 24, 97, 0.65)',
        border: 'none',
        height: 45,
        // fontSize: 30,
        color: '#fff',
        width: 65,
        transition: 'all .3s ease-out',
        '&:hover': {
          backgroundColor: '#441861',
        },
      },
      containerInput: {
        border: 0,
        width: '100%',
        borderBottom: '1px #000 solid',
        padding: '10px 0',
        [theme.breakpoints.down('md')]: {
          margin: 0,
        }
      },
      iconStyle: {
        color: '#fff',
        height: 25,
        width: 25,
      },
    })
  )
);

const SearchProducts: React.FC<TPropsFromRedux & RouteComponentProps> = (
  {
    productsSearch,
    setProductCount,
    addProductToCart,
    setClearSearch,
    loading,
    focus,
    setFocusSearch,
    fetchSearch,
    page,
    per_page,
    total,
    guestCart,
    cart
  }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const intl = useIntl();
  
  const fetch = useCallback(debounce((text: string) => {
    fetchSearch({
      text: text,
      page: 1,
      per_page
    })
  }, 500), [  per_page])
  
  const handleSetCountCart = useCallback(
    (product: IProduct) => {
      if (cart && product) {
        let count = 0
        const item = cart.items.find(item => item.product.id === product.id);
        if (item) {
          count = item.count;
        }
        if (count === 0) {
          addProductToCart({
            product_id: product.id ? product?.id : 0,
            count: 1,
          });
        } else {
          setProductCount({
            product_id: product.id || 0,
            count: ++count,
          });
        }
      }
    },
    [setProductCount, addProductToCart, cart, guestCart?.goods_num, cart?.goods_num, guestCart, ]
  );
  
  return (
    <div style={{
      position: 'relative',
      height: '100%',
      alignItems: 'center',
      display: 'flex',
      flex: 1
    }}>
      <div  style={{
        width: '100%',
        // padding: '0 20px'
      }}>
        <Input
          onFocus={() => setFocusSearch(true)}
          // onBlur={() => setFocusSearch(false)}
          onChange={(e) => {
            setSearchText(e.target.value)
            fetch(e.target.value)
          }}
          type="text"
          value={searchText}
          placeholder="Search Input"
          endAdornment={
            <InputAdornment position="end" style={{
              cursor: 'pointer'
            }}>
              {loading ? ( <CircularProgress size={20}/>) : (
                <>
                {focus ? (
                  <img
                    onClick={() => {
                      setClearSearch()
                      setSearchText('')
                    }}
                    src={toAbsoluteUrl('/images/icon-close-search.svg')} alt=''/>
                ) : (
                  <img src={toAbsoluteUrl('/images/icon-seach.svg')} alt=''/>
                )}
                </>
              )}
            </InputAdornment>
          }
          inputProps={{
            className: classes.input
          }}
          className={`kt-quick-search__input ${classes.containerInput}`}
        />
      </div>
      {focus && productsSearch.length > 0 && (
        <div style={{
          position: 'absolute',
          top: '100%',
          background: '#fff',
          display: 'flex',
          zIndex: 1000,
          flexDirection: 'column',
          padding: '6px 11px 0',
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 177px)',
          width: 'calc(100% + 40px)',
          left: -20
        }}>
          {productsSearch?.map(product => (
            <div style={{
              display: 'flex',
              padding: 10,
              gap: 10,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Link
                style={{
                  display: 'flex',
                  padding: 10,
                  gap: 10,
                  width: '70%'
                }}
                to={`/viewproduct/${product.id}`}
                rel="noopener noreferrer"
                onClick={() => {
                  setFocusSearch(false)
                }}
              >
                {product.photos && product.photos.length > 0 ? (
                  <img
                    alt=''
                    // onClick={() =>
                    //   product.photos &&
                    //   handleImage &&
                    //   handleImage(
                    //     `${API_DOMAIN}/${
                    //       product?.photos?.find(el => el.main)?.small ||
                    //       product.photos[0].small ||
                    //       ''
                    //     }`
                    //   )
                    // }
                    src={`${API_DOMAIN}/${product.photos[0].small}`}
                    className={classes.img}
                  />
                ) : (
                  <div
                    className={classes.img}
                    // onClick={() =>
                    //   product.photos &&
                    //   handleImage &&
                    //   handleImage(
                    //     `${API_DOMAIN}/${
                    //       product?.photos?.find(el => el.main)?.small ||
                    //       product.photos[0].small ||
                    //       ''
                    //     }`
                    //   )
                    // }
                  />
                )}
                <div style={{
                  flex: 1,
                  maxWidth: 225
                }}>
                  
                  <Typography
                    color='inherit'
                    component='p'
                    style={{
                      color: '#000',
                      fontSize: 20,
                      fontWeight: 400,
                    }}
                  >
                    {product.name}
                  </Typography>
                </div>
              </Link>
              <div style={{
                display: 'flex',
                padding: 10,
                gap: 10,
                width: '50%',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <div>
                  {!!product.old_price && !!product.discount_price && (
                    <Typography
                      color='inherit'
                      component='p'
                      style={{
                        lineHeight: 1.3,
                        color: '#000',
                        fontSize: 10,
                        fontWeight: 400,
                        textDecoration: 'line-through'
                      }}
                    >
                      CZK {formatAsThousands(product.old_price)}
                    </Typography>
                  )}
                  <Typography
                    color='inherit'
                    component='p'
                    style={{
                      marginBottom: 4,
                      lineHeight: 1.3,
                      color: '#000000',
                      fontSize: 21,
                      fontWeight: 400,
                    }}
                  >
                    CZK {formatAsThousands(product.price)}
                  </Typography>
                  <Box>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 400,
                      }}
                    >
                      {product.parameter_values?.map(
                        el => el.parameter_name === 'Alkohol %Vol.' && el.value + ' %'
                      )}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 400,
                      }}
                    >
                      {product.parameter_values?.map(
                        el => el.parameter_name === 'Volume, l' && el.value + ' l'
                      )}
                    </Typography>
                  </Box>
                </div>
                <button
                  disabled={!cart}
                  onClick={event => {
                    handleSetCountCart(product)
                  }}
                  className={classes.favBtn}
                  type='button'
                >
                  <AddIcon className={classes.iconStyle}/>
                </button>
              </div>
            </div>
          ))}
          {total === page ? (
            <></>
          ) : (
            <>
              {loading ? (
                <div style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  margin: '15px 0'
                }}>
                  <CircularProgress size={20}/>
                </div>
              ) : (
                <div>
                  <Typography
                    onClick={() => {
                      fetchSearch({
                        text: searchText,
                        page: ++page,
                        per_page
                      })
                    }}
                    color='inherit'
                    component='p'
                    style={{
                      color: '#441861',
                      fontSize: 20,
                      fontWeight: 400,
                      margin: '15px 0',
                      textTransform: 'uppercase',
                      textAlign: 'end',
                      cursor: 'pointer'
                    }}
                  >
                    {intl.formatMessage({ id: 'LOAD.MORE' })}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const connector = connect(
  (states: IAppState) => ({
    productsSearch: states.products.productsSearch,
    loading: states.products.productsSearchLoading,
    page: states.products.productsSearchPage,
    per_page: states.products.productsSearchPerPage,
    total: states.products.productsSearchTotalPage,
    focus: states.products.searchFocus,
    
    guestCart: states.cart.guestCart,
    cart: states.cart.cart,
  }),
  {
    setFocusSearch: productActions.setFocusSearch,
    setClearSearch: productActions.setClearSearch,
    fetchSearch: productActions.fetchSearch,
    
    setProductCount: cartTypesActions.setProductCountRequest,
    addProductToCart: cartTypesActions.addProductRequest,

  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(SearchProducts));
